<template>
  <v-dialog
    scrollable
    :value="dialog"
    @input="$emit('update:dialog',false)" @keydown.esc="closeDialog()" max-width="400"
  >
    <v-card tile class="white">
      <v-card-title class="h5 justify-center pb-0"  v-text="title"/>
      <v-card-text>
        <v-textarea
          v-model="data"
          color="#00cccc"
          rows="8"
          hide-details="auto"
          auto-grow
          dense
          row-height="18px"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          depressed
          small
          tile
          :loading="isLoading"
          color="#00cccc"
          class="white--text"
          @click="submit"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalData',
  props: {
      dialog: Boolean,
      keyData: { type: String, default: 'ruangan' },
      itemData: { type: Array | String, default: () => ([]) },
      title: { type: String, default: 'EDIT LIST' }
  },
  data () {
    return {
      item: null,
      isLoading: false,
    }
  },
  computed: {
    data: {
      get() {
        if(Array.isArray(this.itemData)) {
          return this.itemData.join('\n')
        } else {
          return this.itemData
        }
      },
      set(val) {
        this.item = val
      }
    }
  },
  methods: {
    submit(){
      //localStorage.setItem(this.keyData, this.data)
      this.$store.commit("setting", { key: this.keyData, value: this.item.split('\n') });
      this.$emit('closeDialog');
      this.$emit('getData',this.item)
    },
    closeDialog(){
      this.$emit('closeDialog');
    }
  },
}
</script>
